<template>
  <router-link v-if="to" :class="classes" :to="to">
    <slot />
  </router-link>
  <div
    v-else
    :class="classes"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
      default: null,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hovering: false,
    }
  },
  computed: {
    classes() {
      return {
        ui: true,
        card: true,
        fluid: this.fluid,
        raised: this.hoveringOverClickEvent,
        pointer: this.hoveringOverClickEvent,
        borderless: this.borderless,
        centered: this.centered,
      }
    },
    hoveringOverClickEvent() {
      return (
        !!(this.$vnode.data.nativeOn && this.$vnode.data.nativeOn.click) &&
        this.hovering
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/shadows.less';

.ui.card {
  &.borderless {
    box-shadow: @borderless-shadow;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: @borderless-shadow-hover;
    }
  }

  &.raised {
    transform: translateY(-3px);
  }
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
