<template>
  <container>
    <grid stackable doubling>
      <grid-column sixteen>
        <h1>Templates</h1>
      </grid-column>
      <grid-column four>
        <g-menu left sticky vertical secondary compact :offset="84">
          <menu-item>
            <span class="menu-title"> Jump to... </span>
          </menu-item>
          <menu-item
            v-for="category in momentTemplates"
            :key="category.id"
            :active="$route.hash === `#${category.id}`"
            :to="{ path: `#${category.id}` }"
            class="menu-item"
            @click.prevent="scroll(`#${moment.id}`)"
          >
            <flex align="center">
              <moment-icon
                :background-color-class="category.color"
                :icon="category.icon"
                size="medium"
              />
              <span class="category-name">
                {{ category.name }}
                <g-label
                  v-if="category.name === 'Holidays'"
                  size="mini"
                  horizontal
                  yellow
                  vertically-centered
                >
                  New
                </g-label>
              </span>
            </flex>
          </menu-item>
        </g-menu>
      </grid-column>
      <grid-column twelve>
        <grid
          v-for="category in momentTemplates"
          :id="category.id"
          :key="category.id"
          relaxed
        >
          <grid-column fifteen>
            <flex align="center">
              <moment-icon
                :background-color-class="category.color"
                :icon="category.icon"
                size="large"
              />
              <h2 class="category-name">
                {{ category.name }}
                <g-label
                  v-if="category.name === 'Holidays'"
                  horizontal
                  yellow
                  vertically-centered
                >
                  New
                </g-label>
              </h2>
            </flex>
          </grid-column>
          <cards class="template-cards">
            <card
              v-for="template in category.templates"
              :key="template.id"
              class="template-card"
              borderless
              @click.native="() => selectTemplate(template.id)"
            >
              <card-body centered>
                <div class="emoji">
                  {{ template.emoji }}
                </div>
                <card-header>
                  {{ template.header }}
                </card-header>
                <card-description>
                  {{ template.description }}
                </card-description>
              </card-body>
              <card-footer centered>
                <btn
                  fluid
                  size="large"
                  class="footer-btn"
                  :loading="duplicateLoadingId === template.id"
                >
                  Select
                </btn>
              </card-footer>
            </card>
          </cards>
        </grid>
      </grid-column>
    </grid>
  </container>
</template>

<script>
import momentTemplates from '@/assets/js/momentTemplates'

import { api } from '@/api'
import { toast } from '@/toasts'
import container from '@/components/v2/container.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import cards from '@/components/v2/card/cards.vue'
import card from '@/components/v2/card/card.vue'
import cardBody from '@/components/v2/card/body.vue'
import cardHeader from '@/components/v2/card/header.vue'
import cardDescription from '@/components/v2/card/description.vue'
import cardFooter from '@/components/v2/card/footer.vue'
import btn from '@/components/v2/btn.vue'
import gMenu from '@/components/v2/menu/menu.vue'
import menuItem from '@/components/v2/menu/item.vue'
import flex from '@/components/v2/flex.vue'
import momentIcon from '@/components/v2/moment_icon.vue'
import gLabel from '@/components/v2/label.vue'

export default {
  components: {
    container,
    grid,
    gridColumn,
    cards,
    card,
    cardBody,
    cardDescription,
    cardFooter,
    cardHeader,
    btn,
    gMenu,
    menuItem,
    flex,
    momentIcon,
    gLabel,
  },
  data() {
    return {
      momentTemplates,
      duplicateLoadingId: null,
    }
  },
  mounted() {
    this.scroll(this.$route.hash)
  },
  methods: {
    async selectTemplate(id) {
      try {
        this.duplicateLoadingId = id
        const result = (
          await api.post(`${process.env.VUE_APP_DB_ENDPOINT}/v2/moments`, {
            momentId: id,
          })
        ).data
        await this.patchOnboardingChooseTemplate()
        this.$router.push({ name: 'moment', params: { id: result.id } })
      } catch (error) {
        toast.error(error)
      } finally {
        this.duplicateLoadingId = null
      }
    },
    scroll(selector) {
      if (selector) {
        document.querySelector(selector).scrollIntoView({ behavior: 'smooth' })
        this.$router.push(selector).catch(() => {})
      }
    },
    async patchOnboardingChooseTemplate() {
      try {
        await api.patch(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}/onboarding`,
          {
            chooseTemplate: new Date().getTime(),
          }
        )
      } catch (error) {
        toast.error(error)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';
@import '~@/assets/less/text.less';
@import '~@/assets/less/borders.less';

.menu-title {
  font-size: @title-font-size;
}

.category-name {
  margin-top: 0;
  margin-left: 0.5em;
}

.menu-item {
  .category-name {
    font-size: @title-font-size;
    font-weight: 700;
  }
}

.emoji {
  padding-bottom: 0.75em;
  font-size: @huge-icon-font-size;
}

.template-cards {
  margin: 0.5em 0 1.5em 0;
}

.template-card {
  width: 18em !important;
  height: 28em;
  padding: 1em !important;
}

.footer-btn {
  margin: 0.5rem 0 0 0;
}
</style>
