export default [
  {
    name: 'Getting Started',
    id: 'getting-started',
    color: 'blue-background',
    icon: 'fas fa-rocket',
    templates: [
      {
        id: 3549,
        emoji: '🚀',
        header: 'Gather Launch',
        description: 'Introduce Gather to your team and show them how it works',
      },
      {
        id: 697,
        emoji: '🌱',
        header: '1 Day of Gather Examples',
        description: 'Send yourself examples of best practices',
      },
    ],
  },
  {
    name: 'Onboarding',
    id: 'onboarding',
    color: 'yellow-background',
    icon: 'fas fa-user-plus',
    templates: [
      {
        id: 3538,
        emoji: '✔️',
        header: 'Onboarding Checklist',
        description:
          "Gather's recommended onboarding checklist — designed from best practices",
      },
      {
        id: 3539,
        emoji: '👫',
        header: 'Onboarding Buddy Program',
        description: 'Match a new hire to a buddy and facilitate check-ins',
      },
      {
        id: 3542,
        emoji: '🕒',
        header: '30-60-90 Day Check Ins',
        description: 'Prompt a manager to do regular check-ins',
      },
      {
        id: 3540,
        emoji: '🦸‍♂️',
        header: 'New Hire Custom Channel',
        description: 'Create a channel and coordinate with the key people',
      },
      {
        id: 3541,
        emoji: '#️⃣',
        header: 'Welcome Channel Post',
        description: 'The new hire is introduced by their manager',
      },
    ],
  },
  {
    name: 'Birthday',
    id: 'birthday',
    color: 'pink-background',
    icon: 'fas fa-birthday-cake',
    templates: [
      {
        id: 48,
        emoji: '🧁',
        header: 'Birthday Reminders',
        description: "Gather's recommended reminders to a manager",
      },
      {
        id: 140,
        emoji: '💌',
        header: 'Group Digital Card',
        description: 'Create a channel then send the link for a digital card',
      },
      {
        id: 37,
        emoji: '🎉',
        header: 'Birthday Coordination Channel',
        description: 'Create a temporary channel to coordinate birthday plans',
      },
    ],
  },
  {
    name: 'Work Anniversary',
    id: 'work-anniversary',
    color: 'light-blue-background',
    icon: 'far fa-calendar-check',
    templates: [
      {
        id: 50,
        emoji: '📅',
        header: 'Work Anniversary Recognition',
        description: "Gather's recommended employee recognition prompt",
      },
      {
        id: 28,
        emoji: '👩🏼‍💼',
        header: 'Personal Note from CEO',
        description:
          'Show that leaders care about their employees at an individual level',
      },
    ],
  },
  {
    name: 'Offboarding',
    id: 'offboarding',
    color: 'light-grey-background',
    icon: 'fas fa-user-minus',
    templates: [
      {
        id: 3543,
        emoji: '🏁',
        header: 'Offboarding Checklist (Voluntary)',
        description: 'Coordinate tasks for an employees who has given notice',
      },
      {
        id: 3544,
        emoji: '🚪',
        header: 'Offboarding Checklist (Involuntary)',
        description: 'Coordinate the logistics around a termination',
      },
    ],
  },
  {
    name: 'Parental Leave',
    id: 'parental-leave',
    color: 'light-green-background',
    icon: 'fas fa-baby',
    templates: [
      {
        id: 193,
        emoji: '🤹‍♀️',
        header: 'Reminders for Managers',
        description: 'Help the manager plan and adjust for a new parent',
      },
      {
        id: 194,
        emoji: '🐣',
        header: 'New Parent Return Support',
        description:
          'Prepare and re-integrate a new parent as they return to work',
      },
      {
        id: 144,
        emoji: '👨‍👩‍👧‍',
        header: 'New Parent Buddy Program',
        description: 'Introduce a new parent to another parent on the team',
      },
    ],
  },
  {
    name: 'Internal Comms',
    id: 'company-events',
    color: 'light-purple-background',
    icon: 'fas fa-bullhorn',
    templates: [
      {
        id: 3545,
        emoji: '📈',
        header: 'All Company Meeting',
        description: 'Send reminders and gather feedback',
      },
      {
        id: 3547,
        emoji: '✅',
        header: 'Monthly 1:1 Reminders',
        description: 'Share tips and remind managers',
      },
      {
        id: 3546,
        emoji: '⚙️',
        header: 'New Policy Rollout Checklist',
        description: 'Align your team when launching a new policy',
      },
      {
        id: 692,
        emoji: '📅',
        header: 'Event Reminders + Follow-Up',
        description: 'Send announcements, reminders and follow-ups to events',
      },
      {
        id: 209,
        emoji: '📣',
        header: 'Requests for New Hire Referrals',
        description: 'Share open positions and get referrals',
      },
    ],
  },
  {
    name: 'Performance',
    id: 'performance',
    color: 'light-pink-background',
    icon: 'far fa-compass',
    templates: [
      {
        id: 210,
        emoji: '🎯',
        header: 'Performance Review Reminders',
        description: 'Remind your team to do their performance reviews',
      },
    ],
  },
  {
    name: 'L & D',
    id: 'l-and-d',
    color: 'yellow-background',
    icon: 'fas fa-bookmark',
    templates: [
      {
        id: 971,
        emoji: '🚀',
        header: 'First-time Manager Training',
        description:
          'Prepare your teammates to become leaders in your organization',
      },
      {
        id: 2115,
        emoji: '🧑🏽‍🏫 ',
        header: 'L&D Training Follow Ups',
        description: 'Reinforce learnings after a training',
      },
    ],
  },
  {
    name: 'Compliance',
    id: 'compliance',
    color: 'light-teal-background',
    icon: 'fas fa-clipboard',
    templates: [
      {
        id: 207,
        emoji: '🏥',
        header: 'Open Enrollment Communications',
        description: 'Ensure everyone knows about open enrollment',
      },
    ],
  },
  {
    name: 'Engagement',
    id: 'engagement',
    color: 'light-orange-background',
    icon: 'fas fa-magic',
    templates: [
      {
        id: 696,
        emoji: '🤳🏼',
        header: 'Conversation Starters: Photo Prompts',
        description: 'Start the conversation with prompts to share photos',
      },
      {
        id: 2111,
        emoji: '🏡',
        header: '#WFH Life Photo Sharing',
        description:
          'Share a photo of your WFH space to inspire your teammates',
      },
      {
        id: 695,
        emoji: '🪑',
        header: 'Ergonomics Tips and Reminders',
        description:
          'Tips on ergonomic remote work setups from Life Labs Learning',
      },
    ],
  },
  {
    name: 'Holidays',
    id: 'holidays',
    color: 'light-purple-background',
    icon: 'fas fa-gifts',
    templates: [
      {
        id: 1347,
        emoji: '🏮',
        header: 'January Holidays',
        description: 'MLK Day, Chinese New Year, and Holocaust Remembrance Day',
      },
      {
        id: 6874,
        emoji: '❤️',
        header: 'February Holidays',
        description: "Black History Month, Groundhog Day, and Valentine's Day",
      },
      {
        id: 5358,
        emoji: '👸🏾',
        header: "Women's History Month",
        description: 'Recognize and celebrate Women in March',
      },
      {
        id: 2524,
        emoji: '🦸',
        header: "Women's Health Week",
        description:
          'Reminder for women and girls to make their health a priority (March 14-20)',
      },
      {
        id: 6188,
        emoji: '🌏',
        header: 'AAPI Heritage Month',
        description:
          'Recognize and celebrate Asian American & Pacific Islander heritage',
      },
    ],
  },
]
