<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        content: true,
        full: this.full,
        centered: this.centered,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.full {
  padding: 0 !important;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
